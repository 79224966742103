import React from "react";
import accounts from "../Assets/accounts.png";

const Accounts = () => {
  return (
    <div
      className="flex flex-col lg:flex-row items-center md:px-28 py-6 px-8 justify-center gap-8"
      id="accounts"
    >
      <div className="">
        <h1 className="font-bold text-[#222222] max-w-[400px] text-[54px] leading-tight">
          Три типи облікових записів
        </h1>
        <p className="max-w-[400px] text-[16px] text-[#222222] pt-4">
          Завдяки цьому мобільний додаток стає універсальним інструментом для
          активістів, організацій та команд, що прагнуть зробити громадський
          сектор кращим.
        </p>
      </div>
      <div className="ml-0 lg:ml-8">
        <img src={accounts} alt="Puzzle Image" className="w-[400px]" />
      </div>
    </div>
  );
};

export default Accounts;
