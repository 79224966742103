import React, { useState } from "react";
import "../index.css";
import logo from "../Assets/nav-logo.png";
import { TiThMenuOutline } from "react-icons/ti";

interface NavLink {
  label: string;
  href: string;
}

const navLinks: NavLink[] = [
  { label: "Про додаток", href: "#counts" },
  { label: "Пазл інтересів", href: "#puzzle" },
  { label: "Акаунти", href: "#accounts" },
];

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => setIsOpen(!isOpen);

  return (
    <nav className="flex justify-between items-center md:px-28 py-6 shadow-xl px-8">
      <a href="/">
        <div className="flex items-center gap-2">
          <img src={logo} alt="Horobchyk logo" />
          <div className="text-black text-xl font-bold">Horobchyk</div>
        </div>
      </a>
      <div className="hidden lg:flex space-x-4 items-center">
        {navLinks.map((navLink) => (
          <a
            key={navLink.label}
            href={navLink.href}
            className="text-black hover:text-gray-200"
          >
            {navLink.label}
          </a>
        ))}
        <a
          href="#download"
          className="text-black hover:text-gray-200 bg-[#F1D301] rounded-lg px-4 py-2 uppercase tracking-widest"
        >
          Завантажити
        </a>
      </div>
      <button className="lg:hidden focus:outline-none" onClick={toggleMenu}>
        <TiThMenuOutline size={36} />
      </button>
      <div
        className={`lg:hidden absolute top-20 left-0 w-full h-full bg-[#fff] shadow-xl pt-4 transition duration-fast ease-in-out ${
          isOpen ? "block" : "hidden"
        }`}
      >
        {navLinks.map((navLink) => (
          <a
            key={navLink.label}
            href={navLink.href}
            onClick={() => setIsOpen(false)}
            className="text-black hover:text-gray-200 block px-4 py-2 text-center"
          >
            {navLink.label}
          </a>
        ))}
        <a
          href="#download"
          className="text-black hover:text-gray-200 block px-4 py-2 bg-[#F1D301] text-center uppercase tracking-widest"
          onClick={() => setIsOpen(false)}
        >
          ЗАВАНТАЖИТИ
        </a>
      </div>
    </nav>
  );
};

export default Navbar;
