import React from "react";
import puzzle from "../Assets/puzzle.png";

const Puzzle = () => {
  return (
    <div
      className="flex flex-col lg:flex-row items-center md:px-28 py-6 px-8 justify-center gap-8"
      id="puzzle"
    >
      <div className="">
        <h1 className="font-bold text-[#222222] max-w-[400px] text-[54px] leading-tight">
          Пазл інтересів
        </h1>
        <p className="max-w-[400px] text-[16px] text-[#222222] pt-4">
          Інтерактивний алгоритм, що допомагає користувачам знаходити
          можливості, які відповідають їхнім захопленням. Користувачі обирають
          теми, що їх цікавлять, збирають пазл, а система рекомендує їм
          відповідні можливості. Це простий, персонaлізований та цікавий спосіб
          знайти те, що вам дійсно потрібно.
        </p>
      </div>
      <div className="ml-0 lg:ml-8">
        <img src={puzzle} alt="Puzzle Image" className="w-[400px]" />
      </div>
    </div>
  );
};

export default Puzzle;
