import React from "react";
import heroimg from "../Assets/hero-img.png";
import navlogo from "../Assets/nav-logo.png";

const HeroSection = () => {
  return (
    <>
      <div className="flex flex-col lg:flex-row items-center justify-between md:px-28 py-6 px-8">
        <div className="">
          <img src={navlogo} alt="" />
          <h1 className="font-bold text-[#222222] max-w-[400px] text-[54px] leading-tight">
            Розвиваємо громадський сектор разом
          </h1>
          <p className="max-w-[400px] text-[16px] text-[#222222] pt-4">
            «Horobchyk» стане платформою, де розпочинається розвиток молоді, а
            потенціал метчиться із заходами від громадських організацій та
            можливостями від українських компаній
          </p>
          <div className="mt-4 flex-col lg:flex-row flex">
            <a
              href="#download"
              className="bg-[#F1D301] text-black px-4 py-2 rounded-lg mt-4 uppercase tracking-widest"
            >
              Завантажити
            </a>
            <a
              href="#counts"
              className="bg-[#122790] text-white px-4 py-2 rounded-lg mt-4 uppercase lg:ml-4 tracking-widest"
            >
              Дізнатись більше
            </a>
          </div>
        </div>
        <div className="">
          <img src={heroimg} alt="Hero Image" className="w-full h-full" />
        </div>
      </div>
    </>
  );
};

export default HeroSection;
