import React from "react";
import Navbar from "./Components/NavBar";
import HeroSection from "./Components/HeroSection";
import Counts from "./Components/Counts";
import Puzzle from "./Components/Puzzle";
import Accounts from "./Components/Accounts";
import Download from "./Components/Download";

function App() {
  return (
    <>
      <Navbar />
      <HeroSection />
      <Counts />
      <Puzzle />
      <Accounts />
      <Download />
    </>
  );
}

export default App;
