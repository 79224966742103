import React from "react";

const Counts = () => {
  return (
    <>
      <div
        className="bg-[#132994] flex items-center justify-center gap-12 flex-col lg:flex-row pb-8"
        id="counts"
      >
        <div className="text-center">
          <h1 className="text-[96px] font-bold text-[#F3D400]">50+</h1>
          <p className="text-[16px] text-white uppercase tracking-widest">
            Організацій зареєстровано
          </p>
        </div>
        <div className="text-center">
          <h1 className="text-[96px] font-bold text-[#F3D400]">1000+</h1>
          <p className="text-[16px] text-white uppercase tracking-widest">
            Завантажень
          </p>
        </div>
        <div className="text-center">
          <h1 className="text-[96px] font-bold text-[#F3D400]">95%</h1>
          <p className="text-[16px] text-white uppercase tracking-widest">
            Задоволених користувачів
          </p>
        </div>
      </div>
    </>
  );
};

export default Counts;
