import React from "react";
import d1 from "../Assets/d1.png";
import d2 from "../Assets/d2.png";

const Download = () => {
  return (
    <>
      <div
        className="bg-[#132994] items-center justify-center gap-12 flex-col lg:flex-row pb-8 pt-8"
        id="download"
      >
        <div className="text-center">
          <h1 className="text-[48px] font-bold text-[#F3D400]">
            Завантажити додаток
          </h1>
          <p className="text-[16px] text-white uppercase tracking-widest">
            на iOS та Android
          </p>
        </div>
        <div className="flex flex-col lg:flex-row items-center justify-center gap-5 mt-4">
          <div className="text-center">
            <img src={d1} alt="Download Image" className="w-[200px]" />
          </div>
          <div className="text-center">
            <img src={d2} alt="Download Image" className="w-[200px]" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Download;
